import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`D Ball Kneeling Throw 6×1`}</p>
    <p>{`D Ball Scoop Toss 6×1`}</p>
    <p>{`then,`}</p>
    <p>{`Death by,`}</p>
    <p>{`Minute 1`}</p>
    <p>{`1-Shoulder Press (75/55)`}</p>
    <p>{`2-Clapping Pushups`}</p>
    <p>{`Minute 2`}</p>
    <p>{`2-Shoulder Press`}</p>
    <p>{`4-Clapping Pushups`}</p>
    <p>{`etc`}</p>
    <p>{`2:00 after finishing death by,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`1 Lap Run`}</p>
    <p>{`20-Ring Rows`}</p>
    <p>{`10-Single Arm S2OH, Right Arm (50/35)`}</p>
    <p>{`10-Single Arm S2OH, Left Arm (50/35)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Get your team registered for the Granite Games Fall Throwdown here
at The Ville September 14th!  Only 7 spots left!  Learn more about the
Throwdown here:
 `}<a parentName="em" {...{
            "href": "https://thegranitegames.com/about-throwdowns/"
          }}>{`https://thegranitegames.com/about-throwdowns/   `}</a></em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      